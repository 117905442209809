#root {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #4fd1c5;
  overflow: hidden;
}

#container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);

  .screenshots {
    position: relative;
    height: 50vh;
    overflow: hidden;
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      width: auto;
      border: 10px solid #ffffff4d;
      border-radius: 10px;
    }
  }

  .app_info {
    color: #fff;
    h2 {
      font-size: 50px;
    }
    p {
      font-size: 20px;
    }
    h2,p {
      text-align: center;
    }
  }

  .download {
    text-align: center;
    margin-top: 20px;
    button {
      height: 50px;
      border-radius: 50px;
      padding: 0 16px;
      background: #ffff;
      align-items: center;
      color: #2c7a7b;
      font-weight: 600;
      border: none;
      cursor: pointer;
      span {
        display: inline-block;
        width: 40px;
        border-radius: 50%;
        background: #2c7a7b;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        color: #fff;
        margin-right: 12px;
      }
    }
  }
}
